<template>
  <div class="create-brand" v-cloak>
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="show-project-portal-listing">
            <section class="brands">
              <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
              >
                <div key="1" v-if="data_loader" class="white-row loader-route">
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div v-else class="brand-wrapper">
                  <div
                    class="row"
                    v-if="
                      typeof product_service_details !== 'undefined' &&
                        Object.keys(product_service_details).length
                    "
                  >
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                    >
                        <h4>Product / Service Details : <b>{{ product_service_details.data.product_service_name }}</b> </h4>
                        <hr>
                        <div class="row">
                          <div class="col-sm-12">
                            <div 
                              class="row"
                              v-if="
                                (product_service_details.data.product_service_slug.toLowerCase() == 'domain') ||
                                (product_service_details.data.product_service_slug.toLowerCase() == 'hosting') ||
                                (product_service_details.data.product_service_slug.toLowerCase() == 'maintenance') ||
                                (product_service_details.data.product_service_slug.toLowerCase() == 'ssl-certificate') ||
                                (product_service_details.data.product_service_slug.toLowerCase() == 'domain-and-hosting')
                              ">
                                <div class="col-sm-3">
                                  <!-- <div class="col-sm-3"></div> -->
                                  <p class="project-status">
                                    <b>Domain Url :</b> <br>
                                    {{ product_service_details.data.domain_url }}
                                  </p>
                                </div>
                                <div class="col-sm-3">
                                  <p class="project-status">
                                    <b>Domain / Hosting provider :</b> <br>
                                    {{ product_service_details.data.domain_hosting_provider }}
                                  </p>
                                </div>
                                <div class="col-sm-3"
                                  v-if="(product_service_details.data.product_service_slug.toLowerCase() != 'ssl-certificate') &&
                                  (product_service_details.data.product_service_slug.toLowerCase() != 'maintenance')
                                  "
                                >
                                  <p class="project-status">
                                    <b>Maintenance :</b> <br>
                                    {{ product_service_details.data.do_we_have_maintenance_plan }}
                                  </p>
                                </div>
                                <div class="col-sm-3"
                                  v-if="(product_service_details.data.product_service_slug.toLowerCase() != 'ssl-certificate') &&
                                  (product_service_details.data.product_service_slug.toLowerCase() != 'maintenance')
                                  "
                                >
                                  <p class="project-status">
                                    <b>SSL :</b> <br>
                                    {{ product_service_details.data.ssl_status }}
                                  </p>
                                </div>
                                <div class="col-sm-3">
                                  <p class="project-status">
                                    <b>Total Amount :</b> <br>
                                    {{ product_service_details.data.currency.currency_symbol }}{{  product_service_details.data.amount | numFormat  }}
                                  </p>
                                </div>
                                <div class="col-sm-3">
                                  <p class="project-status">
                                    <b>Starting Date :</b> <br>
                                    {{ product_service_details.data.starting_date }}
                                  </p>
                                </div>
                                <div class="col-sm-3">
                                  <p class="project-status">
                                    <b>Expiry Date :</b> <br>
                                    {{ product_service_details.data.expiry_date }}
                                  </p>
                                </div>

                                <div class="col-sm-3">
                                  <p class="project-status">
                                    <b>Created By :</b> <br>
                                    {{ product_service_details.data.service_created_by.name }} <br>
                                    on , {{ product_service_details.data.created_at }}
                                  </p>
                                </div>
                                <div class="col-sm-12">
                                  <p class="project-status">
                                    <b>Updated By :</b> <br>
                                    {{ product_service_details.data.service_updated_by.name }} <br>
                                    on , {{ product_service_details.data.updated_at }}
                                  </p>
                                </div>
                                
                            </div>
                            <div class="row" v-else>
                              
                              <div class="col-sm-3">
                                <!-- <div class="col-sm-3"></div> -->
                                <p class="project-status">
                                  <b>Package Name :</b> <br>
                                  {{ product_service_details.data.marketing_package_name }}
                                </p>
                              </div>

                              <div class="col-sm-3">
                                <p class="project-status">
                                  <b>Total Amount :</b> <br>
                                  {{ product_service_details.data.currency.currency_symbol }}{{  product_service_details.data.amount | numFormat  }}
                                </p>
                              </div>
                              <div class="col-sm-3">
                                <p class="project-status">
                                  <b>Starting Date :</b> <br>
                                  {{ product_service_details.data.starting_date }}
                                </p>
                              </div>
                              <div class="col-sm-3">
                                <p class="project-status">
                                  <b>Expiry Date :</b> <br>
                                  {{ product_service_details.data.expiry_date }}
                                </p>
                              </div>

                              <div class="col-sm-3">
                                <p class="project-status">
                                  <b>Created By :</b> <br>
                                  {{ product_service_details.data.service_created_by.name }} <br>
                                  on , {{ product_service_details.data.created_at }}
                                </p>
                              </div>
                              <div class="col-sm-3">
                                <p class="project-status">
                                  <b>Updated By :</b> <br>
                                  {{ product_service_details.data.service_updated_by.name }} <br>
                                  on , {{ product_service_details.data.updated_at }}
                                </p>
                              </div>

                            </div>
                          </div>
                          <!-- <div class="col-sm-5"></div> -->
                        </div>
                        <h5>Additional Information</h5>
                        <hr>
                        <div v-if="product_service_details.data.additional_notes" v-html="product_service_details.data.additional_notes"></div>
                        <div v-else>
                            <p>N/A</p>
                        </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-sm-12">
                      No Data Found.
                    </div>
                  </div>
                </div>
              </transition>
            </section>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  watch: {
    prop_product_service_id: {
      handler(val){
        if(val) {
          this.loader = false;
          this.getProductServiceDetails();
        }
      },
      deep: true
    }
  },
  props: ['prop_product_service_id'],
  data() {
    return {
      loader: true,
      data_loader: true,
      product_service_details: {},
    };
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  mounted() {
    this.loader = false;
    this.getProductServiceDetails();
  },
  methods: {
    async getProductServiceDetails() {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "product-service/show/" +
          this.prop_product_service_id + 
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          // alert("hi");
            // console.log(response.data);
          $this.product_service_details = response.data;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
body .white-row {
    box-shadow: none;
}
</style>