<template>
  <div class="viewDashBoard">
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-projects-vue
          v-if="
            (getActiveUserData.userRoles == 'admin') ||
            (getActiveUserData.userRoles == 'subadmin') ||
            (getActiveUserData.userRoles == 'salesmanager') ||
            (getActiveUserData.userRoles == 'salesteamlead') ||
            (getActiveUserData.userRoles == 'devops') ||
            (getActiveUserData.userRoles == 'marketingmanager') ||
            (getActiveUserData.userRoles == 'salesperson')
          "
        ></adminvue-projects-vue>
        <projectcoordinators-vue
          v-else-if="getActiveUserData.userRoles == 'projectcoordinators'"
        ></projectcoordinators-vue>
        <no-component-found v-else></no-component-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminvue from "./components/admin-vue";
import pcvue from "./components/projectcoordinators-vue.vue";
import noComponentFound from "../../components/noComponentFound";

export default {
  components: {
    "no-component-found": noComponentFound,
    "adminvue-projects-vue": adminvue,
    "projectcoordinators-vue" : pcvue
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
