<template>
  <div class="viewDashBoard project-route-index">
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="show-project-portal-listing">
            <!-- // Filter Area -->
            <div class="general-filters">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="row top-gen-heading">
                      <h1 class="global-style-heading">
                        Product Services
                        <a
                          @click.prevent="refreshProjectStatusTable"
                          href=""
                          class="refreshData"
                          ><i class="fas fa-redo-alt"></i
                        ></a>
                      </h1>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="form-actions">
                      <router-link
                        type="submit"
                        id="save-form"
                        class="btn btn-primary"
                        :to="{
                          name: 'product.sercices.create'
                        }"
                        tag="button"
                      >
                        <i class="fas fa-plus-circle"></i> Add New Service
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="">
                      <div class="form-actions">
                        <button
                          v-if="getActiveUserData.userRoles == 'admin'"
                          @click="generateReport"
                          class="btn btn-primary"
                        >
                          Download CSV
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <form
                      v-on:submit.prevent="getIProjectStatusData(1)"
                    >
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group full-width-md">
                              <label for="gender" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                @change="getIProjectStatusData(null)"
                                v-model="formData.product_service_name"
                                :options="product_service_options_options"
                                :settings="{
                                  placeholder: 'Product / Service Name',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group full-width-md">
                              <label for="gender" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                @change="getIProjectStatusData(null)"
                                v-model="formData.status"
                                :options="status_options"
                                :settings="{
                                  placeholder: 'Status',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group full-width-md">
                              <label for="gender" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                @change="getIProjectStatusData(null)"
                                v-model="formData.client_id"
                                :options="project_clients"
                                :settings="{
                                  placeholder: 'Project Clients',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group custom-date-picker padding-custom-css">
                              <label for="from_date">&nbsp;</label>
                              <datepicker
                                :minimumView="'day'"
                                :maximumView="'month'"
                                :initialView="'month'"
                                :format="customFormatterfromdate"
                                placeholder="From"
                                :input-class="'form-control'"
                                :clear-button="true"
                                :value="formData.from_date"
                                @cleared="onClearDateFromDate"
                              >
                                <span
                                  slot="afterDateInput"
                                  class="animated-placeholder"
                                >
                                  <i class="far fa-calendar-alt"></i>
                                </span>
                              </datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group custom-date-picker padding-custom-css">
                              <label for="to_date">&nbsp;</label>
                              <datepicker
                                :minimumView="'day'"
                                :maximumView="'month'"
                                :initialView="'month'"
                                :format="customFormattertodate"
                                placeholder="To"
                                :input-class="'form-control'"
                                :clear-button="true"
                                @cleared="onClearDateToDate"
                                :value="formData.to_date"
                              >
                                <span
                                  slot="afterDateInput"
                                  class="animated-placeholder"
                                >
                                  <i class="far fa-calendar-alt"></i>
                                </span>
                              </datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group full-width">
                              <label for="gender" class="control-label"
                                >&nbsp;</label
                              >
                              <div class="input-group">
                                <input
                                  @keyup.enter="getIProjectStatusData(1)"
                                  v-model="formData.search"
                                  type="text"
                                  class="form-control"
                                  placeholder="Search..."
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn-secondary"
                                    type="button"
                                  >
                                    <i class="fa fa-search"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div  class="form-actions filters-act">
                              <button type="submit" class="btn btn-primary default-global-btn"> Search </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="horizontal-row">
              <hr />
            </div>
            <section class="brands">
              <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
              >
                <div key="1" v-if="data_loader" class="white-row loader-route">
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div v-else class="project-status-wrapper">
                   <transition
                      name="fade"
                      mode="out-in"
                      @beforeLeave="beforeLeave"
                      @enter="enter"
                      @afterEnter="afterEnter"
                    >
                      <div key="1" v-if="data_loader" class="white-row loader-route">
                        <div class="lds-sub-ripple">
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <div v-else key="2" class=" invoices-tab">
                        <div class="table-responsive no-rap-table">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>ID</th>
                                
                                <th>Product / Service Details</th>
                                <th>Client</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                                <th>Total Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody
                              v-if="
                                typeof product_services_listing.data !== 'undefined' &&
                                  Object.keys(product_services_listing.data).length
                              "
                            >
                              <tr
                                v-for="(p_service, ps_index) in product_services_listing.data"
                                :key="ps_index"
                              >
                                <td>
                                  {{ p_service.id }} 
                                </td>
                                <td>
                                  <div v-if="
                                    (p_service.product_service_slug.toLowerCase() == 'domain') ||
                                    (p_service.product_service_slug.toLowerCase() == 'hosting') ||
                                    (p_service.product_service_slug.toLowerCase() == 'domain-and-hosting')
                                  ">
                                    <p class="eclipse-para main-heading">
                                      <strong>{{ p_service.product_service_name }}</strong>
                                    </p>
                                    <p class="project-status eclipse-para">
                                      <b>Domain Url :</b>
                                      {{ p_service.domain_url }}
                                    </p>
                                    <p class="project-status eclipse-para">
                                      <b>Domain / Hosting provider :</b>
                                      {{ p_service.domain_hosting_provider }}
                                    </p>
                                    <p class="project-status eclipse-para">
                                      <b>Maintenance :</b>
                                      {{ p_service.do_we_have_maintenance_plan }}
                                    </p>
                                    <p class="project-status eclipse-para">
                                      <b>SSL :</b>
                                      {{ p_service.ssl_status }}
                                    </p>
                                    <p class="project-status eclipse-para">
                                      <b>Auto Billing :</b>
                                      <span v-if="p_service.auto_billing == 'yes'" class="badge badge-success text-capitalize px-2 ml-1" style="padding:2px 10px;">{{ p_service.auto_billing }}</span>
                                      <span v-if="p_service.auto_billing == 'no'" class="badge badge-danger text-capitalize px-2 ml-1" style="padding:2px 10px;">{{ p_service.auto_billing }}</span>
                                    </p>
                                  </div>
                                  <div v-else-if="
                                    (p_service.product_service_slug.toLowerCase() == 'marketing')
                                  ">
                                    <p class="eclipse-para main-heading">
                                      <strong>{{ p_service.product_service_name }}</strong>
                                    </p>
                                    <p class="project-status eclipse-para">
                                      <b>Package :</b>
                                      {{ p_service.marketing_package_name }}
                                    </p>
                                  </div>
                                  <div v-else>
                                      <p class="eclipse-para main-heading">
                                        <strong>{{ p_service.product_service_name }}</strong>
                                      </p>
                                  </div>
                                </td>
                                <td>
                                  <p class="eclipse-para">
                                    <strong>
                                      <router-link
                                          class="user-title eclipse-para global-para-link2"
                                          :to="{
                                            path: '/clients/' + p_service.client_name.id
                                          }"
                                          tag="a"
                                        >
                                        {{ p_service.client_name.name }} 
                                      </router-link>
                                      </strong>
                                  </p>
                                </td>
                                <td>
                                  {{ p_service.expiry_date }}
                                </td>
                                <td>
                                  <span :class="p_service.status.toLowerCase()">{{ p_service.status }}</span>
                                </td>
                                <td>
                                  {{ p_service.currency.currency_symbol }}{{  p_service.amount | numFormat  }}
                                </td>
                                <td>
                                  <div class="download-box text-right">
                                    <a 
                                      href="#" 
                                      @click.prevent="showAdditionalInfo(ps_index)" 
                                      class="action-icons"
                                    >
                                      <p>
                                        <i class="fas fa-info-circle"></i>
                                      </p>
                                    </a>
                                    <router-link
                                      v-if="(getActiveUserData.userRoles == 'devops') ||
                                      (getActiveUserData.userRoles == 'admin' ) "
                                      class="action-icons"
                                      title="Edit!"
                                      data-placement="top"
                                      :to="{
                                        path: '/product-services/edit/' + p_service.id
                                      }"
                                      tag="a"
                                    >
                                      <p>
                                        <i class="far fa-edit"></i>
                                      </p>
                                    </router-link>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="11">
                                  <no-record-found></no-record-found>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                   </transition>
                   <div class="row">
                      <div class="col-sm-12">
                        <pagination
                          class="pagination justify-content-center"
                          :data="product_services_listing"
                          :limit="1"
                          @pagination-change-page="getIProjectStatusData"
                        ></pagination>
                      </div>
                    </div>
                </div>
              </transition>
            </section>
          </div>
        </div>
      </transition>
      <modal
        name="additional-info-modal"
        height="auto"
        width="60%"
        :scrollable="true"
      >
        <div class="ModalLoader">
          <show-additional-data-info :prop_product_service_id="prop_product_service_id"></show-additional-data-info>
        </div>
    </modal>
    </div>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import Datepicker from "vuejs-datepicker";
// import Dropdown from "bp-vuejs-dropdown";
import norecordfound from "../../../components/no-record-found";
import modal_component_show_additional_data from "./sub-children/show-additional-info";
export default {
  name: "product.services",
  components: {
    Select2,
    "no-record-found": norecordfound,
    "show-additional-data-info" : modal_component_show_additional_data,
    Datepicker
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      formData: {
        search: "",
        from_date : null,
        to_date : null,
        client_id : null,
        status : null,
        product_service_name : null,
        per_page : 30,
      },
      loader: true,
      data_loader: true,
      product_services_listing: {},
      prop_product_service_id : null,
      prop_product_service_index : null,
      project_clients: null,
      per_page_option: [
        {
          id: 30,
          text: "30"
        },
        {
          id: 50,
          text: "50"
        },
        {
          id: 100,
          text: "100"
        },
        {
          id: 300,
          text: "300"
        },
        {
          id: 500,
          text: "500"
        }
      ],
      status_options: [
        {
          id: "active",
          text: "Active"
        },
        {
          id: "expired",
          text: "Expired"
        },
        {
          id: "canceled",
          text: "Canceled"
        },
        {
          id: "suspended",
          text: "Suspended"
        }
      ],
      product_service_options_options: [
        {
          id: "ssl-certificate",
          text: "SSL Certificate"
        },
        {
          id: "domain",
          text: "Domain"
        },
        {
          id: "hosting",
          text: "Hosting"
        },
        {
          id: "domain-and-hosting",
          text: "Domain And Hosting"
        },
        {
          id: "marketing",
          text: "Marketing"
        },
        {
          id : 'maintenance',
          text : 'Maintenance',
        },
      ],
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getIProjectStatusData();
    this.getclients();
    this.loader = false;
  },
  methods: {
    async generateReport() {
      const $this = this;
      $this.$store.dispatch("generalLoader", {
        response: true
      });
      await window
        .axios({
           url:
          "product-service/download-csv?" +
          "search=" +
          this.formData.search + 
          
          "&client_id=" +
          this.formData.client_id +
          
          "&status=" +
          this.formData.status +

          "&product_service_name=" +
          this.formData.product_service_name +

          "&from_date=" +
          this.formData.from_date +

          "&to_date=" +
          this.formData.to_date +

          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          method: "GET",
          responseType: "blob" // important
        })
        .then(response => {
          // console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "product_services.csv"
          );
          document.body.appendChild(link);
          link.click();
          $this.$store.dispatch("generalLoader", {
            response: false
          });
          // $this.$store.dispatch("generalLoader", {
          //   response: false
          // });
        });
    },
    // General Filtesr
    async getclients() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-clients"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_clients = response.data.data;
        }
      });
    },
    showAdditionalInfo(lead_index) {
      this.prop_product_service_index = lead_index;
      this.prop_product_service_id = this.product_services_listing.data[this.prop_product_service_index].id;
      this.$modal.show("additional-info-modal");
    },
    hideTermsCondition() {
      this.$modal.hide("additional-info-modal");
    },
    customFormatterfromdate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.from_date = new_val;
      return new_val;
    },
    customFormattertodate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.to_date = new_val;
      return new_val;
    },
    onClearDateFromDate() {
      this.formData.from_date = null;
    },
    onClearDateToDate() {
      this.formData.to_date = null;
    },
    refreshProjectStatusTable() {
      this.getIProjectStatusData();
    },
    async getIProjectStatusData(page = 1, action = null) {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "product-service" +
          "?" +
          "&page=" +
          page +

          "&search=" +
          this.formData.search + 
          
          "&client_id=" +
          this.formData.client_id +
          
          "&status=" +
          this.formData.status +

          "&product_service_name=" +
          this.formData.product_service_name +

          "&from_date=" +
          this.formData.from_date +

          "&to_date=" +
          this.formData.to_date +

          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.product_services_listing = response.data;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
span.active {
    background: rgba(0, 128, 0, 0.72);
    color: #fff !important;
    font-size: 11px;
    border-radius: 10px;
    text-transform: capitalize;
    font-weight: bold !important;
    line-height: 26px !important;
    padding: 2px 18px 4px;
    text-align: center;
}
span.expired {
    background: red;
    color: #fff !important;
    font-size: 11px;
    border-radius: 10px;
    text-transform: capitalize;
    font-weight: bold !important;
    line-height: 26px !important;
    padding: 2px 18px 4px;
    text-align: center;
}
span.suspended {
    background: red;
    color: #fff !important;
    font-size: 11px;
    border-radius: 10px;
    text-transform: capitalize;
    font-weight: bold !important;
    line-height: 26px !important;
    padding: 2px 18px 4px;
    text-align: center;
}
span.canceled {
    background: gray;
    color: #fff !important;
    font-size: 11px;
    border-radius: 10px;
    text-transform: capitalize;
    font-weight: bold !important;
    line-height: 26px !important;
    padding: 2px 18px 4px;
    text-align: center;
}
.custom-bottom-padding {
  padding-bottom: 35px;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 95%;
        .input-group {
          input {
            border-radius: 3px !important;
            background: #f9f9f9;
            letter-spacing: 0.28px;
            font-size: 14px;
            padding: 3px 15px;
            color: #364460;
            border: none;
            font-weight: 200;
            height: auto;
          }
          button {
            background: #f9f9f9;
            color: #3b4c54;
            border: none;
            padding: 4px 8px;
          }
        }
      }
      .form-actions {
        width: 100%;
        &.filters-act {
          width: 95%;
        }
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
      &.main-heading {
          color: #1190EE;
          padding-bottom: 3px;
      }
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.brands {
        padding-bottom: 0px;
        padding-top: 0;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: auto;
          margin-bottom: 30px;
          .header-top {
            position: absolute;
            right: 26px;
            z-index: 100;
            top: 19px;
            .bp-dropdown__btn {
              border: none;
            }
            .bp-dropdown__body {
              padding: 0;
              background-color: #fff;
              box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
              z-index: 9999;
              border-radius: 4px;
              border: none;
              margin-top: 10px;
              .list-group {
                .list-group-item {
                  border: none;
                  position: relative;
                  display: block;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                  padding: 10px 20px 10px 7px;
                  text-align: left;
                  a {
                    color: #212529;
                  }
                }
              }
            }
          }
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 0;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.additional-notes div * {
    all: inherit !important;
}
// Extra large devices (lg and less)
@media screen and (max-width: 1199px) {
}
// Extra large devices (md and less)
@media screen and (max-width: 991px) {
}

// Extra large devices (sm and up)
@media screen and (max-width: 767px) {
  h1.global-style-heading {
    padding-bottom: 12px;
  }
}

// Extra large devices (xs and up)
@media screen and (max-width: 575px) {
}

// Extra large devices (xl, 1200px and up)
@media (min-width: 1200) {
}
</style>
